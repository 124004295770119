// 常量路由

import systemManageRouter from '@/router/modules/systemManage'


export const constantRoutes = [
    {
        path: '/',
        redirect: '/home',
        meta: {
            hidden: true
        }
    },
    {
        path: '/login',
        meta: {
            hidden: true
        },
        component: () => import('@/views/login/index')
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            icon: 'el-icon-s-home',
            name: '首页'
        },
        component: () => import('../views/home/index.vue')
    }
]

// 任意路由
export const anyRoutes = [
    //
]

// 动态路由
export const asyncRoutes = () => [


    {
        path: '/biaoxun',
        name: 'Biaoxun',
        component: () => import('@/views/biaoxun/index.vue'),
        meta: {name: '标讯管理', icon: 'el-icon-menu'},
        children: [
            // 标讯管理
            {
                path: '/biaoxun/list',
                name: 'BiaoXunList',
                component: () => import('@/views/biaoxun/biaoxun/list.vue'),
                meta: {name: '标讯列表', icon: 'el-icon-menu'}
            },
            {
                path: '/biaoxun/add',
                name: 'BiaoXunAdd',
                component: () => import('@/views/biaoxun/biaoxun/update.vue'),
                meta: {
                    name: '新增标讯',
                    hidden: true,
                    activeMenu: '/biaoxun/list'
                }
            },
            {
                path: '/biaoxun/edit/:id?',
                name: 'BiaoXunEdit',
                component: () => import('@/views/biaoxun/biaoxun/update.vue'),
                meta: {
                    name: '编辑标讯',
                    hidden: true,
                    activeMenu: '/biaoxun/list'
                }
            },
            // 标讯导航
            {
                path: '/guide/list',
                name: 'GuideList',
                component: () => import('@/views/biaoxun/guide/list.vue'),
                meta: {name: '标讯导航', icon: 'el-icon-menu'}
            },
            {
                path: '/guide/add',
                name: 'GuideAdd',
                component: () => import('@/views/biaoxun/guide/update.vue'),
                meta: {
                    name: '新增导航',
                    hidden: true,
                    activeMenu: '/guide/list'
                }
            },
            {
                path: '/guide/edit/:id?',
                name: 'GuideEdit',
                component: () => import('@/views/biaoxun/guide/update.vue'),
                meta: {
                    name: '编辑导航',
                    hidden: true,
                    activeMenu: '/guide/list'
                }
            }
        ]
    },

    {
        path: '/agent',
        name: 'Agent',
        component: () => import('@/views/agent/index.vue'),
        meta: {name: '代理商管理', icon: 'el-icon-s-shop'},
        children: [
            {
                path: '/agent/list',
                name: 'AgentList',
                component: () => import('@/views/agent/agent/list.vue'),
                meta: {name: '代理商'}
            },
            {
                path: '/agent/employee/list',
                name: 'AgentEmployeeList',
                component: () => import('@/views/agent/employee/list.vue'),
                meta: {name: '员工'}
            }
        ]
    },
    {
        path: '/finance',
        name: 'Finance',
        component: () => import('@/views/finance/index.vue'),
        meta: {name: '财务管理', icon: 'el-icon-s-shop'},
        children: [
            {
                path: '/finance/list',
                name: 'FinanceList',
                component: () => import('@/views/finance/salary/list.vue'),
                meta: {name: '当月收益'}
            },
        ]
    },
    {
        path: '/order',
        name: 'OrderManage',
        component: () => import('@/views/orderManage/index.vue'),
        meta: {name: '订单管理', icon: 'el-icon-s-shop'},
        children: [
            {
                path: '/order/list',
                name: 'OrderList',
                component: () => import('@/views/orderManage/order/list.vue'),
                meta: {name: '订单管理'}
            },
            // {
            //     path: '/orderGive/list',
            //     name: 'OrderGiveList',
            //     component: () => import('@/views/orderManage/orderGive/list.vue'),
            //     meta: {name: '赠送'}
            // },
            {
                path: '/payVoucher/list',
                name: 'PayVoucherList',
                component: () => import('@/views/orderManage/userPayVoucher/list.vue'),
                meta: {name: '支付凭证'}
            },
            {
                path: '/invoice/list',
                name: 'InvoiceList',
                component: () => import('@/views/orderManage/invoice/list.vue'),
                meta: {name: '发票管理'}
            },
        ]
    },

    {
        path: '/user/list',
        name: 'UserList',
        component: () => import('@/views/user/list.vue'),
        meta: {name: '会员管理', icon: 'el-icon-s-order'}
    },
    {
        path: '/user/detail/:id?',
        name: 'UserDetail',
        component: () => import('@/views/user/detail.vue'),
        meta: {name: '会员详情', icon: 'el-icon-s-order', hidden: true,}
    },

    systemManageRouter
]
