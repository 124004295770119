import { http } from '@/http/axios.js'
// 站点site
export function getSiteDetailAPI() {
  return http({
    url: '/api/company/site/detail',
    method: 'get',

  })
}


export function getWaitStatAPI(params) {
  return http({
    url: '/api/manage/user/pay_voucher/getWaitCount',
    method: 'get',
    params
  })
}
